import React, { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";
import { FaRegHeart } from "react-icons/fa";
import DonateButton from "../components/button/donate";
import Cart from "../components/cart";

const donationOpts = [10, 20, 30, 50, 100];

const Donate = () => {
  const [openDonation, setOpenDonationAmount] = useState("");

  useEffect(() => {
    document.addEventListener("snipcart.ready", function() {});
    return () => {
      document.removeEventListener("snipcart.ready", function() {});
    };
  }, []);

  const enterOpenDonationAmount = (e) => {
    const value = e.currentTarget.value;
    if (Number(value) <= 5000) {
      setOpenDonationAmount(value);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (typeof window !== "undefined") {
      await window.Snipcart.api.items.add({
        name: `Donate One Time for ${openDonation}`,
        id: `subscription-onetime-${openDonation}`,
        url: `https://youthmissionoutreach.org/donate-validation`,
        price: openDonation,
        taxable: false,
        custom1Name: "Program",
        custom1Options:
          "General|Youth Night|Tutoring|Health Crusaders|2 Be Men|Dance Troop|God's Little Princess|Food Pantry",
        paymentInterval: null,
      });
    }
    setOpenDonationAmount(null);
  };

  return (
    <Layout>
      <SEO title="Donation" />
      <Cart />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="display-1">
                <FaRegHeart />
              </h2>
            </div>
            <div className="mt-5">
              <p>
                <span className="display-4">YMO</span>
                We are proudly a non-profit, non-corporate and non-compromised.
                People like you help us fight the good fight. It is because of
                your support we are able to help build communities. Will you
                give today?
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-3">Specific Amount Donation</h2>
              <p>
                <small>
                  Make a difference today! Contribute any amount specified below
                  to support our cause. Every donation makes an impact on our
                  outreach. Remember, your generosity creates positive change.
                  Click a button below to donate now.
                </small>
              </p>
              <div className="row">
                <div className="col">
                  <p className="font-weight-bold">One time</p>
                  <ul
                    style={{
                      listStyle: "none",
                      padding: "0",
                    }}
                  >
                    {donationOpts.map((price) => (
                      <li className="pb-3">
                        <DonateButton price={price} name="One Time" />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col">
                  <p className="font-weight-bold">Monthly</p>
                  <ul
                    style={{
                      listStyle: "none",
                      padding: "0",
                    }}
                  >
                    {donationOpts.map((price) => (
                      <li className="pb-3">
                        <DonateButton monthly price={price} name="Monthly" />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="pb-3">
                <h2>Open Donation Amount</h2>
                <p>
                  <small>
                    Join us in making a difference! Your support matters, no
                    matter the size. Give what feels right to you, and help us
                    do more for the community. Your generosity fuels our
                    efforts. Enter an amount below and click the donation button
                    to donate that amount and be a part of the change.
                  </small>
                </p>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <form onSubmit={onSubmitForm}>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <input
                            aria-label="Amount (to the nearest dollar)"
                            class="form-control form-control-lg"
                            type="number"
                            min="0"
                            max="5000"
                            placeholder="Enter an amount"
                            onChange={enterOpenDonationAmount}
                            value={openDonation || ""}
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          id="donate-btn"
                          className="btn btn-primary btn-lg"
                          disabled={openDonation ? false : true}
                          value="submit"
                        >
                          Donate
                        </button>
                      </div>
                      <small>$5000 is the max amount you can enter.</small>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Donate.propTypes = {};

export default Donate;
