import React from "react";
import PropTypes from "prop-types";

const DonateButton = ({ price, name, monthly }) => {
  return (
    <button
      style={{
        minWidth: "100px",
      }}
      className="btn btn-primary btn-lg snipcart-add-item"
      data-item-name={`Donate ${name} for ${price}`}
      data-item-id={`subscription-${name
        .split(" ")
        .join("")
        .toLowerCase()}-${price}`}
      data-item-url={`https://youthmissionoutreach.org/donate`}
      data-item-price={price}
      data-item-payment-interval={monthly ? "Month" : null}
      data-item-payment-interval-count="1"
      data-item-custom1-name="Program"
      data-item-custom1-options="General|Youth Night|Tutoring|Health Crusaders|2 Be Men|Dance Troop|God's Little Princess|Food Pantry"
      data-item-custom2-name="Additional comments"
      data-item-custom2-type="textarea"
      data-item-taxable={false}
    >
      {`$${price}`}
    </button>
  );
};

DonateButton.propTypes = {
  price: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  monthly: PropTypes.string.isRequired,
};

export default DonateButton;
